// Emotion types
/// <reference types="@emotion/react/types/css-prop" />
import React, { useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
    Layout,
    Button,
    VideoHero,
    HeroText,
    Case,
    Map,
    Headline,
    StandOutImage,
    StandOutText,
    Navigation,
    Footer,
    Metadata,
} from "../components";

// Video
import oceanWebM from "../assets/videos/ocean.webm";
import cloudsWebM from "../assets/videos/clouds.webm";
import winterForestWebM from "../assets/videos/winter-forest.webm";
import campaignWebM from "../assets/videos/campaign.webm";
import oceanMp4 from "../assets/videos/ocean.mp4";
import cloudsMp4 from "../assets/videos/clouds.mp4";
import winterForestMp4 from "../assets/videos/winter-forest.mp4";
import campaignMp4 from "../assets/videos/campaign.mp4";

// Subtitles
import campaignSubs from "file-loader!../assets/subtitles/campaign.vtt";

// Images
import stovePan from "../assets/images/stove-pan.jpg";
import eFerry from "../assets/images/e-ferry.jpg";
import transportTruck from "../assets/images/transport-truck.jpg";
import windFarm from "../assets/images/wind-farm.jpg";
import datacenter from "../assets/images/datacenter.jpg";
import wifiSvg from "../assets/images/wifi.svg";

// Styling

const StickyBtn = styled.a`
    position: sticky;
    top: 80px;
    z-index: 10;
`;

// Markup
const Index = () => {
    const chargingRef = useRef<HTMLDivElement>(null);
    const steelRef = useRef<HTMLDivElement>(null);
    const roadRef = useRef<HTMLDivElement>(null);
    const ferryRef = useRef<HTMLDivElement>(null);
    const renewableRef = useRef<HTMLDivElement>(null);
    const datacenterRef = useRef<HTMLDivElement>(null);

    // Order important for scrollTo, same as array of map-markers
    const articleRefs = [
        chargingRef,
        datacenterRef,
        steelRef,
        roadRef,
        ferryRef,
        renewableRef,
    ];

    return (
        <>
            <Metadata />
            <Navigation />
            <Layout>
                <VideoHero
                    primary
                    webM={campaignWebM}
                    mp4={campaignMp4}
                    subtitles={campaignSubs}
                >
                    <h1>Bli en del av bra saker för klimatet</h1>
                </VideoHero>
                <StickyBtn
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.vattenfall.se/elavtal/?icmp=301/teckna-elavtal/Teckna%20elavtal%20-%20Vattenfall"
                >
                    <Button intent="primary">Teckna fossilfritt elavtal</Button>
                </StickyBtn>
                <VideoHero webM={oceanWebM} mp4={oceanMp4}>
                    <HeroText color="#000">
                        Som elkund hos Vattenfall får du alltid fossilfri el,
                        oavsett vilket elavtal du väljer. Dessutom blir du en
                        del av bra saker som händer på vår resa mot ett
                        fossilfritt liv.
                    </HeroText>
                </VideoHero>
                <Headline
                    primary="Bra saker som händer för klimatet just nu"
                    secondary="Upptäck några exempel på utvecklingen du blir en del av."
                />
                <Map articleRefs={articleRefs} />

                <VideoHero
                    webM={winterForestWebM}
                    mp4={winterForestMp4}
                    ref={chargingRef}
                >
                    <HeroText color="#fff">
                        Vattenfalls nätverk av laddstationer för elbilar växer.
                        Idag finns över 4000 laddstationer landet runt.
                    </HeroText>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://incharge.vattenfall.se/ladda-paa-vaegen"
                    >
                        <Button intent="secondary">Läs mer</Button>
                    </a>
                </VideoHero>
                <Case
                    ref={datacenterRef}
                    image={datacenter}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Fossilfria datacenter</h4>
                    <p>
                        Allt fler globala leverantörer av digitala tjänster
                        väljer att placera sina datacenter i norra Sverige, i
                        serverhallar som drivs av fossilfri el.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/hallbarhet/fossilfria-datacenter/"
                    >
                        Läs mer
                    </a>
                    <StandOutImage src={wifiSvg} />
                </Case>
                <Case
                    ref={steelRef}
                    image={stovePan}
                    imageOn="left"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Fossilfritt stål</h4>
                    <p>
                        Tillsammans med gruvbolaget LKAB och stålföretaget SSAB
                        har vi startat HYBRIT. Vi gör det möjligt att producera
                        fossilfritt stål där kokskolet byts ut mot väte
                        producerat av el.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/trender-och-innovation/hybrit-fossilfri-stalproduktion/"
                    >
                        Läs mer
                    </a>
                </Case>
                <Case
                    ref={roadRef}
                    image={transportTruck}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Trådlösa elvägar</h4>
                    <p>
                        Världens första trådlösa elvägar för tunga lastbilar och
                        bussar tar form. Vi gör det möjligt att köra tunga
                        transporter mellan flygplatsen och Visby helt på el.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/eldrivna-transporter/elektriska-vagar/"
                    >
                        Läs mer
                    </a>
                </Case>
                <Case
                    ref={ferryRef}
                    image={eFerry}
                    imageOn="left"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Eldrivna färjor</h4>
                    <p>
                        Sedan ett par år trafikerar elhybridfärjan E/S Movitz
                        Mälaren i Stockholm. Färjan tar tio minuter att ladda
                        för en timmes trafik.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/eldrivna-transporter/elfarjan-es-movitz/"
                    >
                        Läs mer
                    </a>
                </Case>
                <Case
                    ref={renewableRef}
                    image={windFarm}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Mer förnybar el</h4>
                    <p>
                        Under de senaste åren har produktionen av el från sol,
                        vind och biomassa fördubblats i EU.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/hallbarhet/fossilfri-el-gor-nytta-i-europa/"
                    >
                        Läs mer
                    </a>
                    <StandOutText
                        primary="100%"
                        secondary="Mer förnybar energi"
                    />
                </Case>
                <VideoHero webM={cloudsWebM} mp4={cloudsMp4}>
                    <h2>Förnybar eller fossilfri el – vad är skillnaden?</h2>
                    <div
                        css={css`
                            max-width: 705px;
                        `}
                    >
                        <HeroText small color="#fff">
                            Fossilfri el betyder att elen produceras helt utan
                            utsläpp av koldioxid. Vissa fossilfria energislag är
                            dessutom förnybara. Vattenkraft, solkraft och
                            vindkraft produceras med energikällor som inte tar
                            slut. El från kärnkraft är fossilfri men inte
                            förnybar, eftersom uranet som används vid
                            produktionen inte är en outsinlig källa.
                        </HeroText>
                        <HeroText small color="#fff">
                            All el vi säljer i Sverige är fossilfri. Om du vill,
                            kan du välja vilken sorts energi du vill ha till
                            ditt elavtal. Det kostar inget extra.
                        </HeroText>
                    </div>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/elavtal/energikallor/"
                    >
                        <Button intent="secondary">
                            Läs mer om energikällorna
                        </Button>
                    </a>
                </VideoHero>
            </Layout>
            <Footer />
        </>
    );
};

export default Index;

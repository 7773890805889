import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import metadataImage from "../assets/images/metadata-image.jpg";

// Markup
export const Metadata = () => {
    const { site } = useStaticQuery(query);
    const { defaultTitle, titleTemplate, defaultDescription, siteUrl } =
        site.siteMetadata;

    return (
        <Helmet title={defaultTitle} titleTemplate={titleTemplate}>
            <html lang="sv-SE" />
            <meta name="description" content={defaultDescription} />
            <meta name="image" content={metadataImage} />
            <meta property="og:url" content={siteUrl} />
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.4/jquery.min.js"></script>
        </Helmet>
    );
};

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl
            }
        }
    }
`;

import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { animated, useSpring } from "react-spring";
import logo from "@vf-alchemy/vattenfall-design-system/dist/img/vf-logo.svg";

// Styling
const Root = styled(animated.header)`
    background-color: #fff;
    /* position: sticky; */
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
`;

// Markup
export const Header = () => {
    const [showMenu, setShowMenu] = useState<boolean>(true);
    const showMenuRef = useRef<boolean>(showMenu);
    const lastScroll = useRef<number>(0);

    const headerAnimation = useSpring({
        transform: `translateY(${showMenu ? "0%" : "-100%"})`,
        padding: 0,
        marginBottom: 0,
    });

    const changeHeader = () => {
        const shouldShow = window.scrollY < lastScroll.current;
        if (showMenuRef.current !== shouldShow) {
            setShowMenu(shouldShow);
            showMenuRef.current = shouldShow;
        }
        lastScroll.current = window.scrollY;
    };

    // useEffect(() => {
    //     window.addEventListener("scroll", changeHeader);
    //     return () => {
    //         window.removeEventListener("scroll", changeHeader);
    //     };
    // }, []);

    return (
        <Root className="vf-topbar" style={headerAnimation}>
            <div className="vf-container">
                <a href="#" className="vf-topbar__logo">
                    <img src={logo} width="200" />
                </a>
            </div>
        </Root>
    );
};

import React, { ReactNode } from "react";

// Interface

interface ButtonProps {
    children: ReactNode;
    disabled?: boolean;
    size?: "large" | "medium";
}

interface DefaultProps extends ButtonProps {
    intent?: "primary" | "secondary" | "tertiary";
    variant?: "default";
}

interface OutlineProps extends ButtonProps {
    intent?: "primary" | "secondary";
    variant: "outline";
}

// Markup
export const Button = ({
    children,
    intent = "primary",
    variant = "default",
    disabled = false,
    size = "large",
}: DefaultProps | OutlineProps) => {
    if (variant === "default") {
        return (
            <button
                type="button"
                disabled={disabled}
                className={`vf-btn ${
                    size === "large" ? "vf-btn--lg" : ""
                } vf-btn--${intent}`}
            >
                {children}
            </button>
        );
    }

    if (variant === "outline") {
        return (
            <button
                type="button"
                disabled={disabled}
                className={`vf-btn ${
                    size === "large" ? "vf-btn--lg" : ""
                } vf-btn--outline-${
                    intent === "primary" ? "dark" : "secondary"
                }`}
            >
                {children}
            </button>
        );
    }

    return null;
};

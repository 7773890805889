import React, { useState } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import styled from "@emotion/styled";
import { MapMarker } from ".";
import { mapStyle } from "../theme/map-style";

// Images
import eFerry from "../assets/images/circle-e-ferry.png";
import forest from "../assets/images/circle-forest.png";
import stovePan from "../assets/images/circle-stove-pan.png";
import transport from "../assets/images/circle-transport.png";
import windFarm from "../assets/images/circle-wind-farm.png";
import datacenter from "../assets/images/circle-datacenter.png";

type location = {
    position: google.maps.LatLng | google.maps.LatLngLiteral;
    text: string;
    image: string;
};
interface MapProps {
    articleRefs: React.RefObject<HTMLDivElement>[];
}
interface MarkersProps {
    articleRefs: React.RefObject<HTMLDivElement>[];
    locations: location[];
}

const containerStyle = {
    width: "100%",
    height: "100%",
    padding: 0,
};

const center = {
    lat: 62.98583179,
    lng: 16.321998712,
};

const MapContainer = styled.div`
    width: 100%;
    height: 100vh;
    max-height: 697.8px;
    padding: 0;
`;

// Data
// Keep order identical as articlerefs
const locations: location[] = [
    {
        position: { lat: 60.93, lng: 13.711 }, // Dalarna
        text: "Fler laddstationer",
        image: forest,
    },
    {
        position: { lat: 65.317, lng: 21.48 }, // Piteå
        text: "Fossilfria datacenter",
        image: datacenter,
    },
    {
        position: { lat: 63.646, lng: 17.38 }, // Västerbotten
        text: "Fossilfritt stål",
        image: stovePan,
    },
    {
        position: { lat: 57.681, lng: 18.523 }, // Gotland
        text: "Trådlösa elvägar",
        image: transport,
    },
    {
        position: { lat: 59.007, lng: 17.622 }, // Stockholm
        text: "Eldriven sjötrafik",
        image: eFerry,
    },
    {
        position: { lat: 56.849, lng: 14.348 }, // Skåne
        text: "Mer förnybar el",
        image: windFarm,
    },
];

// Markup
const MapComponent = ({ articleRefs }: MapProps) => (
    <MapContainer>
        <LoadScript googleMapsApiKey="AIzaSyC6saIqcHQ0_YxlnRMiHJfdIjPslDlZvBM">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={4.8}
                options={{
                    styles: mapStyle,
                    disableDefaultUI: true,
                    gestureHandling: "none",
                    draggableCursor: "default",
                }}
            >
                <MapMarkers locations={locations} articleRefs={articleRefs} />
            </GoogleMap>
        </LoadScript>
    </MapContainer>
);

const MapMarkersContainer = styled.div`
    position: relative;
`;

const MapMarkers = ({ locations, articleRefs }: MarkersProps) => {
    const [active, setActive] = useState<number | null>(null);

    return (
        <MapMarkersContainer>
            {locations.map(({ position, image, text }, idx) => (
                <MapMarker
                    key={`marker-${idx}`}
                    articleRef={articleRefs[idx]}
                    idx={idx}
                    position={position}
                    text={text}
                    image={image}
                    active={active}
                    setActive={setActive}
                />
            ))}
        </MapMarkersContainer>
    );
};

export const Map = React.memo(MapComponent);

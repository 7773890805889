import React from "react";
import styled from "@emotion/styled";

// Interface
interface HeadlineProps {
    primary: string;
    secondary?: string;
}

// Styling
const Root = styled.div`
    margin-top: 3rem;
    text-align: center;
`;

const Primary = styled.h2``;
const Secondary = styled.p`
    margin-bottom: 0;
`;

// Markup
export const Headline = ({ primary, secondary }: HeadlineProps) => {
    return (
        <Root>
            <Primary>{primary}</Primary>
            {secondary && <Secondary>{secondary}</Secondary>}
        </Root>
    );
};

import React, { ReactNode, forwardRef, ForwardedRef } from "react";
import styled from "@emotion/styled";

// Interfaces
interface CaseProps {
    image: string;
    imageOn?: "left" | "right";
    children: ReactNode;
    offset?: number;
    backgroundColor: string;
}

interface RootProps {
    imageOnRight: boolean;
}

// Styling
const Root = styled.div<{ backgroundColor: string }>`
    display: flex;
    width: 100%;
    /* height: 800px; */
    align-items: center;
    justify-content: center;
    padding: 0 15px;

    background-color: ${(props) => props.backgroundColor};

    margin: 2rem -2rem;
    padding: 0;

    ${(props) => props.theme.mq[2]} {
        width: calc(100% + 80px);
        height: calc(100% + 100px);
        padding: 0 15px;
        margin: 5rem 0;
    }
`;

const ContentContainer = styled.div<{ imageOnRight: boolean; offset: number }>`
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1380px;
    flex-direction: column-reverse;
    align-items: center;

    ${(props) => props.theme.mq[2]} {
        height: 400px;
        flex-direction: ${(props) =>
            props.imageOnRight ? "row" : "row-reverse"};
        padding: 0 5rem;
    }
`;
const ImageSide = styled.img<RootProps & { offset: number }>`
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${(props) => props.theme.mq[2]} {
        position: absolute;
        height: calc(
            100% + ${(props) => (props.offset === 0 ? "100px" : "0px")}
        );
        width: 45%;
        transform: translateY(${(props) => props.offset}px);
        top: ${(props) => (props.offset === 0 ? "-50px" : "0")};
        bottom: 0;
        ${(props) => (props.imageOnRight ? "right: 5rem;" : "left: 5rem")}
    }
`;

const TextSide = styled.div`
    padding: 2rem;

    ${(props) => props.theme.mq[2]} {
        width: 44%;
    }
`;

// Markup
const Article = (
    {
        image,
        imageOn = "right",
        offset = 0,
        children,
        backgroundColor,
    }: CaseProps,
    ref: ForwardedRef<HTMLDivElement>
) => (
    <Root backgroundColor={backgroundColor} ref={ref}>
        <ContentContainer offset={offset} imageOnRight={imageOn === "right"}>
            <TextSide>{children}</TextSide>
            <ImageSide
                src={image}
                offset={offset}
                imageOnRight={imageOn === "right"}
            />
        </ContentContainer>
    </Root>
);

export const Case = forwardRef(Article);

import React from "react";
import { MarkerProps, OverlayView } from "@react-google-maps/api";

import styled from "@emotion/styled";

// Interface
interface MapMarkerProps extends MarkerProps {
    idx: number;
    text: string;
    image: string;
    active: number | null;
    setActive: React.Dispatch<React.SetStateAction<number | null>>;
    articleRef: React.RefObject<HTMLDivElement>;
}

// Styling
const Container = styled.div<{ active: boolean }>`
    position: absolute;
    z-index: ${(props) => (props.active ? 10 : 1)} !important;
`;

const Circle = styled.div`
    position: absolute;
    background-color: #ffda00;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
`;

const Info = styled.div`
    width: 120px;
    height: 120px;
    padding: 0.5rem;
    transform: translate(-50%, -50%);
    cursor: pointer;

    ${(props) => props.theme.mq[2]} {
        width: 180px;
        height: 180px;
    }
`;

const Background = styled.img`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const Text = styled.p`
    color: #fff;
    position: absolute;
    text-shadow: 0 0 5px #000;
    line-height: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 600;
`;

// Markup
export const MapMarker = ({
    idx,
    position,
    text,
    image,
    active,
    setActive,
    articleRef,
}: MapMarkerProps) => {
    const scrollToArticle = () => {
        const articlePosition = articleRef.current?.offsetTop;
        const desktopUser = window.innerWidth > 992;
        if (articlePosition && desktopUser) {
            const offsetPosition = articlePosition - 150;
            window.scrollTo({ top: offsetPosition, behavior: "smooth" });
        }
    };

    return (
        <OverlayView position={position} mapPaneName="overlayMouseTarget">
            <Container active={idx === active}>
                {idx !== active && (
                    <Circle onMouseOver={() => setActive(idx)} />
                )}
                {idx === active && (
                    <Info
                        onMouseOut={() => setActive(null)}
                        onClick={() => scrollToArticle()}
                    >
                        <Background src={image} />
                        <Text>{text}</Text>
                    </Info>
                )}
            </Container>
        </OverlayView>
    );
};

import React, { ForwardedRef, forwardRef, ReactNode } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

// Interface
interface VideoHeroProps {
    mp4: string;
    webM: string;
    primary?: boolean;
    children?: ReactNode;
    subtitles?: string;
}

// Styling
const Section = styled.section<{ primary: boolean }>`
    max-width: none;
    height: calc(100vh - 100px);
    max-height: 697.8px;
    padding: 2rem;
    position: relative;
    overflow: hidden;
    border-left: none;
    border-right: none;
    margin: ${(props) => (props.primary ? 0 : "2rem")} 0 2rem;
    ${(props) =>
        props.theme.mq[2] &&
        props.primary &&
        css`
            padding-bottom: 56.25%;
        `};
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    pointer-events: ${(props) => (props.controls ? "all" : "inherit")};
    ${(props) => props.theme.mq[2]} {
        object-fit: inherit;
    }

    ::cue {
        font-family: "Vattenfall Hall NORDx", -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
        background: none;
        background-color: rgba(0, 0, 0, 0) !important;
        text-shadow: 0 0 10px #000;
    }
    ::-webkit-media-text-track-display-backdrop {
        background-color: rgba(0, 0, 0, 0) !important;
    }
`;

const Content = styled.div<{ primary: boolean }>`
    pointer-events: ${(props) => (props.primary ? "none" : "inherit")};
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    padding: 2rem;

    & > * {
        text-align: center;
    }
`;

// Markup
export const VideoHeroWithoutRef = (
    { children, mp4, webM, primary = false, subtitles }: VideoHeroProps,
    ref: ForwardedRef<HTMLDivElement>
) => (
    <Section className="vf-hero" primary={primary} ref={ref}>
        <Video autoPlay muted playsInline loop controls={primary}>
            <source src={webM} type="video/webm" />
            <source src={mp4} type="video/mp4" />
            {subtitles && (
                <track
                    label="Svenska"
                    kind="subtitles"
                    srcLang="sv"
                    src={subtitles}
                ></track>
            )}
        </Video>
        <Content className="vf-hero__content" primary={primary}>
            {children}
        </Content>
    </Section>
);

export const VideoHero = forwardRef(VideoHeroWithoutRef);

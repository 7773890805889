import React from "react";
import styled from "@emotion/styled";

// Interface
interface StandOutImageProps {
    src: string;
}

// Styling
const Root = styled.img`
    position: absolute;
    width: 100px;
    right: 10%;
    bottom: 0;

    ${(props) => props.theme.mq[2]} {
        width: 280px;
        bottom: -34%;
        left: 21%;
    }
`;

// Markup
export const StandOutImage = ({ src }: StandOutImageProps) => {
    return <Root src={src} />;
};

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

// Interface
interface HeroTextProps {
    children: string;
    color?: string;
    small?: boolean;
}

const sharedStyles = css`
    min-width: 300px;
    max-width: 744px;
`;

const Default = styled.h2`
    ${sharedStyles}
    color: ${(props) => props.color};

    margin-bottom: 3rem;
`;
const Small = styled.p`
    ${sharedStyles}

    line-height: 1.75rem;
`;

// Markup
export const HeroText = ({
    children,
    color = "#000",
    small = false,
}: HeroTextProps) => {
    if (!small) {
        return <Default color={color}>{children}</Default>;
    } else {
        return <Small color={color}>{children}</Small>;
    }
};

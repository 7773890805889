import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { ThemeProvider } from "@emotion/react";
import * as theme from "../theme";

// Vattenfall CSS modules -- https://digitaldesign.vattenfall.com
import "@vf-alchemy/vattenfall-design-system/dist/css/reboot.min.css";
import "@vf-alchemy/vattenfall-design-system/dist/css/main.min.css";

// Interface
interface LayoutProps {
    children?: ReactNode;
}

// Styling
const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    ${(props) => props.theme.mq[2]} {
        padding: 40px;
    }

    & > * {
        margin: 3rem 0;
    }

    h2 {
        line-height: 38px;

        ${(props) => props.theme.mq[2]} {
            line-height: 52px;
        }
    }
`;

// Markup
export const Layout = ({ children }: LayoutProps) => {
    return (
        <ThemeProvider theme={theme}>
            <Root>{children}</Root>
        </ThemeProvider>
    );
};

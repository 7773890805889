import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Root = styled.div`
    width: 100%;
    background: #ebf2f3;
    max-width: none;
    margin: 0;
    nav p {
        margin-bottom: 5px;
    }
    nav a {
        font-size: 16px;
        margin-bottom: 12px;
        margin-top: 0;
        padding: 0;
        line-height: normal;
    }

    .vf-container {
        width: 100%;
        margin: auto;
        max-width: 1425px;
    }
    .item-list {
        width: 20%;
        text-align: left;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
            text-align: left;
            margin-bottom: 30px;
        }
    }
    .vf-row {
        @media screen and (max-width: 768px) {
            display: table;
            margin: auto;
        }
    }
    .vf-footer__social-links {
        @media screen and (max-width: 768px) {
            a {
                margin-top: 8px;
                padding-right: 3px;
            }
        }
    }
    .vf-footer {
        padding-bottom: 40px;
    }
`;

const CopyRight = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: auto;
    display: flex;
    border-top: 1px solid white;
    padding-top: 16px;
    padding-bottom: 28px;
    justify-content: space-between;

    a {
        color: #000;
        margin-left: 10px;
        margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
        display: block;
        text-align: center;
        > div {
            margin-bottom: 10px;
        }
    }
`;

// Markup
export const Footer = () => {
    return (
        <Root>
            <footer className="vf-footer">
                <div className="vf-container">
                    <div className="vf-row">
                        <div className="item-list">
                            <nav className="vf-footer__nav">
                                <p
                                    className="vf-footer__nav-item"
                                    css={css`
                                        font-weight: 600;
                                    `}
                                >
                                    Våra erbjudanden
                                </p>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/elavtal/"
                                >
                                    Elavtal
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/varmepumpar/"
                                >
                                    Värmepumpar
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/solceller/"
                                >
                                    Solceller
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/ladda-elbilen/"
                                >
                                    Ladda elbilen
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/kunderbjudanden/"
                                >
                                    För dig som kund
                                </a>
                            </nav>
                        </div>
                        <div className="item-list">
                            <nav className="vf-footer__nav">
                                <p
                                    className="vf-footer__nav-item"
                                    css={css`
                                        font-weight: 600;
                                    `}
                                >
                                    Kundservice
                                </p>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/kundservice/"
                                >
                                    Behöver du hjälp?
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/kundservice/flytt/"
                                >
                                    Flytt
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/kundservice/avbrott/elavbrott/"
                                >
                                    Strömavbrott
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/fjarrvarme/avbrott/"
                                >
                                    Fjärrvärmeavbrott
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/kundservice/avtalsvillkor/"
                                >
                                    Avtalsvillkor och rättigheter
                                </a>
                            </nav>
                        </div>
                        <div className="item-list">
                            <nav className="vf-footer__nav">
                                <p
                                    className="vf-footer__nav-item"
                                    css={css`
                                        font-weight: 600;
                                    `}
                                >
                                    Mina sidor
                                </p>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/logga-in/"
                                >
                                    Logga in på Mina sidor
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/mina-sidor/min-forbrukning/"
                                >
                                    Se din förbrukning
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/mina-sidor/mina-fakturor/"
                                >
                                    Se dina fakturor
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/mina-sidor/mina-avtal/"
                                >
                                    Se dina avtal
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.se/kunderbjudanden/"
                                >
                                    Erbjudanden
                                </a>
                            </nav>
                        </div>
                        <div className="item-list">
                            <nav className="vf-footer__nav">
                                <p
                                    className="vf-footer__nav-item"
                                    css={css`
                                        font-weight: 600;
                                    `}
                                >
                                    Läs mer
                                </p>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://careers.vattenfall.com/se/"
                                >
                                    Jobba hos oss
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfalleldistribution.se/"
                                >
                                    Vattenfall Eldistribution
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://group.vattenfall.com/se"
                                >
                                    Om Vattenfall
                                </a>
                            </nav>
                        </div>
                        <div className="item-list">
                            <div
                                className="vf-footer__social-links"
                                css={css`
                                    text-align: left;
                                `}
                            >
                                <p
                                    css={css`
                                        font-size: 16px;
                                        font-weight: 600;
                                        margin-top: 3px;
                                        margin-bottom: 0;
                                        text-align: left;
                                    `}
                                >
                                    Följ oss
                                </p>

                                <a href="https://www.facebook.com/vattenfallSE">
                                    <span
                                        className="p-1 vf-tooltip vf-tooltip--blue-solid vf-icon-facebook"
                                        data-tooltip="vf-icon-facebook"
                                    ></span>
                                </a>
                                <a href="https://www.instagram.com/vattenfall/">
                                    <span
                                        className="p-1 vf-tooltip vf-tooltip--blue-solid vf-icon-instagram"
                                        data-tooltip="vf-icon-instagram"
                                    ></span>
                                </a>
                                <a href="https://www.youtube.com/user/vattenfall">
                                    <span
                                        className="p-1 vf-tooltip vf-tooltip--blue-solid vf-icon-youtube"
                                        data-tooltip="vf-icon-youtube"
                                    ></span>
                                </a>
                                <a href="https://twitter.com/Vattenfall_Se">
                                    <span
                                        className="p-1 vf-tooltip vf-tooltip--blue-solid vf-icon-twitter"
                                        data-tooltip="vf-icon-twitter"
                                    ></span>
                                </a>
                                <a href="https://www.linkedin.com/company/vattenfall">
                                    <span
                                        className="p-1 vf-tooltip vf-tooltip--blue-solid vf-icon-linkedin"
                                        data-tooltip="vf-icon-linkedin"
                                    ></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <CopyRight>
                <div>© Vattenfall AB</div>
                <div>
                    <a href="https://www.vattenfall.se/personuppgifter/">
                        Personuppgifter
                    </a>
                    <a href="https://www.vattenfall.se/om-cookies/">
                        Om cookies
                    </a>
                </div>
            </CopyRight>
        </Root>
    );
};

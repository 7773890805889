export const colors = {
    primary: {
        solar_yellow: "#FFDA00",
        ocean_blue: "#2071B5",
        aura_white: "#FFFFFF",
        coal_black: "#000000",
        magnetic_gray: "#4E4B48",
    },
    secondary: {
        dark_green: "#005C63",
        dark_blue: "#1E324F",
        pink: "#D1266B",
        dark_purple: "#85254B",
        green: "#3DC07C",
        red: "#F93B18",
        purple: "#9B62C3",
    },
    background: {
        light_yellow: "#FFFEE5",
        light_green: "#EDF9F3",
        light_blue: "#EBF2F3",
        light_blue_alt: "#EDF1F6",
        light_red: "#FEF0EA",
        light_grey: "#F2F2F2",
    },
    tertiary: {
        ash_blue: "#869BAD",
        ash_blue_dark: "#69788C",
        lighter_grey: "#E6E6E6",
        light_grey: "#CCCCCC",
        medium_grey: "#999999",
        dark_grey: "#666666",
    },
    energy_source: {
        hydro: "#2DA55D",
        wind: "#4FCC51",
        solar: "#81E0A8",
        biomass: "#375E4E",
        coal: "#E88A74",
        gas: "#D85067",
        nuclear: "#203D5D",
        district_heating: "#A376CC",
    },
};

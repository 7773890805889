import React from "react";
import styled from "@emotion/styled";

// Interface
interface StandoutProps {
    primary: string;
    secondary?: string;
}

// Styling
const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transform: none;
    margin-top: 3rem;

    ${(props) => props.theme.mq[2]} {
        position: absolute;
        transform: translate(22px, 26px);
    }
    ${(props) => props.theme.mq[3]} {
        position: absolute;
        transform: translate(100px, 42px);
    }
`;

const Title = styled.p`
    font-size: 42px;
    margin-bottom: 10px;
    font-weight: 600;

    ${(props) => props.theme.mq[1]} {
        font-size: 70px;
        margin-bottom: 14px;
    }

    ${(props) => props.theme.mq[2]} {
        font-size: 100px;
        margin-bottom: 32px;
    }
    ${(props) => props.theme.mq[3]} {
        font-size: 124px;
        margin-bottom: 32px;
    }
`;

const Subtitle = styled.p`
    margin-bottom: 0px;
    ${(props) => props.theme.mq[1]} {
        font-weight: 600;
        margin-bottom: inherit;
    }
`;

// Markup
export const StandOutText = ({ primary, secondary }: StandoutProps) => {
    return (
        <Root>
            <Title>{primary}</Title>
            {secondary && <Subtitle>{secondary}</Subtitle>}
        </Root>
    );
};
